import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import { MAP_CONSTANTS } from '@/modules/permissions/constants'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const useHasMapPermissions = (mapName: string): boolean => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()

  if (mapName === MAP_CONSTANTS.ROUTES && !region?.hasMdsData) return false

  const orgHasAllMaps = !!permissions && !permissions.organizationSettings.maps
  let orgHasPermissions =
    !!permissions?.organizationSettings.maps?.includes(mapName) || orgHasAllMaps
  let regionHasPermissions = !!region?.allowedMaps?.includes(mapName)

  if ([MAP_CONSTANTS.TRIPS, MAP_CONSTANTS.PARKING].includes(mapName)) {
    orgHasPermissions =
      orgHasPermissions || !!permissions?.organizationSettings.maps?.includes('eval')
    regionHasPermissions = regionHasPermissions || !!region?.allowedMaps?.includes('eval')
  }

  return orgHasPermissions && regionHasPermissions
}
