// @ts-nocheck
import _ from 'lodash'
import { DateTime } from 'luxon'
import { action, computed, makeObservable, observable, runInAction, toJS, values } from 'mobx'

import { adjustDateRange } from '@/common/utils/date'
import { enumStringValues } from '@/common/utils/enum'
import { formatText } from '@/common/utils/text'
import dashboards from '@/data/dashboards.json'
import { PermissionKey } from '@/modules/api/openapi/models/PermissionKey'
import { PermissionsObjType } from '@/modules/api/openapi/models/PermissionsObjType'
import { removeQueryCache } from '@/modules/api/queryClient'
import { apipyRequest, internalApi } from '@/modules/api/request'
import { getOperatorDisplayName } from '@/modules/operator'
import policyComplianceNotificationsStore from '@/modules/permissions/adminPanel/policyComplianceNotificationsStore'
import {
  buildGetPermissionsForObjQueryOptions,
  buildGetRolesForUserQueryOptions,
} from '@/modules/permissions/hooks'
import appUIStore from '@/stores/appUIStore'
import metroStore from '@/stores/metroStore'

function getKeysWhereTrue(map) {
  // takes a map and returns the keys where the value is truthy
  // sure is a lot of code for something really simple
  const ret = []
  map.forEach((v, k) => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!v) {
      ret.push(k)
    }
  })
  return ret
}

// NOTE: this list is duplicated in terminal/populus_lib/populus_lib/schemas.py
export const organizationTypes = [
  'City',
  'Community Org',
  'Consultant',
  'MPO',
  'Operator',
  'State/Federal Agency',
  'Test',
  'Transit Agency',
  'University Consultant',
  'University Regulator',
]

class PermissionsModalStore {
  activeTab = 0
  organizationNames

  /* 
    see https://app.asana.com/0/1140737766672633/1199898310216975
    and https://docs.google.com/spreadsheets/d/1CK4FntNUffCr745mNE9tkN4A99bBcasoF6fpiWthpH4/edit#gid=0
  */

  populusUuid
  organizationName = ''
  organizationType: (typeof organizationTypes)[number] = null
  organizationUuid
  operatorId // whether this org is an operator, and which one
  organizationLogo = null
  allRegions
  selectedRegions = []
  allOperators = false
  selectedOperators = []
  subscriptionPlan = 'basic'
  vehicleAndTripCounts = 'basic'
  export = false
  coverage = false
  home = false
  mobilityMetrics = false
  feeSummary = true
  complianceReporting = false
  complianceDetails = false
  mobilityMapsDownload = false
  disaggregatedData = false
  mobilityManager = false
  curbManager = false
  curbMetricsPage = false
  curbParkingInsights = false
  curbParkingMap = false
  curbTransactionsTable = false
  curbDistrictAnalysis = false
  curbRateTester = false
  curbOccupancy = false
  curbRegulationsLibrary = false
  bikeshareStationReport = false
  curbRegulations = false
  curbFeesAndRevenue = false
  curbActivity = false
  mfaRequired = false
  liveMapMobile = false
  policiesLibrary = false
  enableTrialPeriod = false
  trialDateRange = {
    start: null,
    end: null,
  }
  allMaps = false
  maps = observable.map()
  allMobilityDashboards = false
  mobilityDashboards = observable.map()
  users = observable.map()

  newUserEmail = ''
  newUserPassword = ''

  userMessage
  organizationMessage

  hasEdits = false // enable save button
  mode = 'create' // one of create or update

  micromobilityPlans = observable.map()

  // these are used to track revenue
  // feeStructure can be 'paid' | 'unpaid' | 'pilot' -> duplicated in terminal/populus_lib/populus_lib/schemas.py
  mobilityFeeStructure = null
  curbFeeStructure = null
  mobilityPopulusEnforcedInvoicing = false
  curbPopulusEnforcedInvoicing = false

  constructor() {
    makeObservable(this, {
      activeTab: observable,
      organizationName: observable,
      organizationType: observable,
      organizationUuid: observable,
      operatorId: observable,
      organizationLogo: observable,
      allRegions: observable,
      selectedRegions: observable,
      allOperators: observable,
      selectedOperators: observable,
      subscriptionPlan: observable,
      vehicleAndTripCounts: observable,
      export: observable,
      coverage: observable,
      home: observable,
      mobilityMetrics: observable,
      feeSummary: observable,
      complianceReporting: observable,
      complianceDetails: observable,
      mobilityMapsDownload: observable,
      disaggregatedData: observable,
      mobilityManager: observable,
      curbManager: observable,
      curbMetricsPage: observable,
      curbParkingInsights: observable,
      curbParkingMap: observable,
      curbTransactionsTable: observable,
      curbDistrictAnalysis: observable,
      curbRateTester: observable,
      curbOccupancy: observable,
      curbRegulationsLibrary: observable,
      bikeshareStationReport: observable,
      curbRegulations: observable,
      curbFeesAndRevenue: observable,
      curbActivity: observable,
      mfaRequired: observable,
      liveMapMobile: observable,
      policiesLibrary: observable,
      enableTrialPeriod: observable,
      trialDateRange: observable,
      allMaps: observable,
      allMobilityDashboards: observable,
      newUserEmail: observable,
      newUserPassword: observable,
      userMessage: observable,
      organizationMessage: observable,
      hasEdits: observable,
      mobilityFeeStructure: observable,
      curbFeeStructure: observable,
      mobilityPopulusEnforcedInvoicing: observable,
      curbPopulusEnforcedInvoicing: observable,
      setActiveTab: action,
      setHasEdits: action,
      setAllOperators: action,
      setAllRegions: action,
      setAllMaps: action,
      setAllMobilityDashboards: action,
      setOrganizationName: action,
      setOrganizationType: action,
      setFeeStructure: action,
      setPopulusEnforcedInvoicing: action,
      setNewUserEmail: action,
      setNewUserPassword: action,
      setSelectedRegions: action,
      selectedRegionsJS: computed,
      setSelectedOperators: action,
      selectedOperatorsJS: computed,
      setSubscriptionPlan: action,
      setVehicleAndTripCounts: action,
      toggleMapChecked: action,
      toggleMobilityDashboardChecked: action,
      toggleExport: action,
      toggleHome: action,
      toggleMobilityMetrics: action,
      toggleFeeSummary: action,
      toggleComplianceReporting: action,
      toggleComplianceDetails: action,
      toggleMobilityMapsDownload: action,
      toggleDisaggregatedData: action,
      toggleCoverage: action,
      toggleMobilityManager: action,
      toggleCurbManager: action,
      toggleCurbMetricsPage: action,
      toggleCurbParkingInsights: action,
      toggleCurbParkingMap: action,
      toggleCurbTransactionsTable: action,
      toggleCurbDistrictAnalysis: action,
      toggleCurbRateTester: action,
      toggleCurbFeesAndRevenue: action,
      toggleCurbActivity: action,
      toggleMfaRequired: action,
      toggleLiveMapMobile: action,
      togglePoliciesLibrary: action,
      toggleEnableTrialPeriod: action,
      setTrialDateRangeDefault: action,
      setTrialDateRange: action,
      regionMetros: computed,
      allMetroOperators: computed,
      availableRegionsMenuOptions: computed,
      availableDashboardsMenuOptions: computed,
      availableMobilityDashboardsMenuOptions: computed,
      newOrganization: action,
      cancelNewOrganization: action,
      organizationJson: computed,
      clearOrganizationMessage: action,
      clearUserMessage: action,
      clear: action,
      setMobilityManagerPermissions: action,
      setOrganizationPermissions: action,
      setUsers: action,
      activePlan: computed,
      availableUserPermissions: computed,
    })
  }

  setActiveTab(activeTab) {
    this.activeTab = activeTab
  }

  setHasEdits(bool) {
    this.hasEdits = bool
  }

  setAllOperators(allOperators) {
    this.allOperators = allOperators
    this.setHasEdits(true)
  }

  setAllRegions(allRegions) {
    this.allRegions = allRegions
    this.setHasEdits(true)
  }

  setAllMaps(allMaps) {
    this.allMaps = allMaps

    // this is a weird special case where we need to set the maps to the plan
    if (allMaps === false && this.subscriptionPlan !== 'custom') {
      const { maps } = toJS(this.micromobilityPlans.get(this.subscriptionPlan))
      maps.forEach(map => this.maps.set(map, true))
    }

    this.setHasEdits(true)
  }

  setAllMobilityDashboards(allMobilityDashboards) {
    this.allMobilityDashboards = allMobilityDashboards
    this.setHasEdits(true)
  }

  setOrganizationName(organizationName) {
    this.organizationName = organizationName
    this.setHasEdits(true)
  }

  setOrganizationType(organizationType) {
    this.organizationType = organizationType
    this.setHasEdits(true)
  }

  setFeeStructure(feeStructure, product) {
    if (product === 'mobility') this.mobilityFeeStructure = feeStructure
    if (product === 'curb') this.curbFeeStructure = feeStructure
    product && this.setHasEdits(true)
  }

  setPopulusEnforcedInvoicing(enforcedInvoicing, product) {
    if (product === 'mobility') this.mobilityPopulusEnforcedInvoicing = enforcedInvoicing
    if (product === 'curb') this.curbPopulusEnforcedInvoicing = enforcedInvoicing
    product && this.setHasEdits(true)
  }

  setNewUserEmail(newUserEmail) {
    this.newUserEmail = newUserEmail
  }

  setNewUserPassword(newUserPassword) {
    this.newUserPassword = newUserPassword
  }

  setSelectedRegions(selectedRegions) {
    this.selectedRegions = selectedRegions.sort()
    this.setHasEdits(true)
  }

  get selectedRegionsJS() {
    return toJS(this.selectedRegions || [])
  }

  setSelectedOperators(selectedOperators) {
    this.selectedOperators = selectedOperators.sort()
    this.setHasEdits(true)
  }

  get selectedOperatorsJS() {
    return toJS(this.selectedOperators || [])
  }

  setSubscriptionPlan(subscriptionPlan) {
    this.subscriptionPlan = subscriptionPlan
    if (subscriptionPlan !== 'custom') {
      // set to the default attributes of the plan
      this.setMobilityManagerPermissions(toJS(this.micromobilityPlans.get(subscriptionPlan)))
    }
    this.setHasEdits(true)
  }

  setVehicleAndTripCounts(vehicleAndTripCounts) {
    this.vehicleAndTripCounts = vehicleAndTripCounts
    this.setHasEdits(true)
  }

  mapIsChecked(map) {
    return !!this.maps.get(map)
  }

  toggleMapChecked(map) {
    this.maps.set(map, !this.mapIsChecked(map))
    this.setHasEdits(true)
  }

  mobilityDashboardIsChecked(mobilityDashboard) {
    return this.mobilityDashboards.get(mobilityDashboard)
  }

  toggleMobilityDashboardChecked(mobilityDashboard) {
    this.mobilityDashboards.set(
      mobilityDashboard,
      !this.mobilityDashboardIsChecked(mobilityDashboard)
    )
    this.setHasEdits(true)
  }

  toggleExport() {
    this.export = !this.export
    this.setHasEdits(true)
  }

  toggleHome() {
    this.home = !this.home
    this.setHasEdits(true)
  }

  toggleMobilityMetrics() {
    this.mobilityMetrics = !this.mobilityMetrics
    this.setHasEdits(true)
  }

  toggleFeeSummary() {
    this.feeSummary = !this.feeSummary
    this.setHasEdits(true)
  }

  toggleComplianceReporting() {
    this.complianceReporting = !this.complianceReporting
    this.setHasEdits(true)
  }

  toggleComplianceDetails() {
    this.complianceDetails = !this.complianceDetails
    this.setHasEdits(true)
  }

  toggleMobilityMapsDownload() {
    this.mobilityMapsDownload = !this.mobilityMapsDownload
    this.setHasEdits(true)
  }

  toggleDisaggregatedData() {
    this.disaggregatedData = !this.disaggregatedData
    this.setHasEdits(true)
  }

  toggleMfaRequired() {
    this.mfaRequired = !this.mfaRequired
    this.setHasEdits(true)
  }

  toggleLiveMapMobile() {
    this.liveMapMobile = !this.liveMapMobile
    this.setHasEdits(true)
  }

  toggleCoverage() {
    this.coverage = !this.coverage
    this.setHasEdits(true)
  }

  toggleMobilityManager() {
    this.mobilityManager = !this.mobilityManager
    this.setHasEdits(true)
  }

  toggleCurbManager() {
    this.curbManager = !this.curbManager
    this.setHasEdits(true)
  }

  toggleCurbMetricsPage() {
    this.curbMetricsPage = !this.curbMetricsPage
    this.setHasEdits(true)
  }

  toggleCurbRegulations() {
    this.curbRegulations = !this.curbRegulations
    this.setHasEdits(true)
  }

  toggleCurbRegulationsLibrary() {
    this.curbRegulationsLibrary = !this.curbRegulationsLibrary
    this.setHasEdits(true)
  }

  toggleBikeshareStationReport() {
    this.bikeshareStationReport = !this.bikeshareStationReport
    this.setHasEdits(true)
  }

  toggleCurbParkingInsights() {
    this.curbParkingInsights = !this.curbParkingInsights
    this.setHasEdits(true)
  }

  toggleCurbParkingMap() {
    this.curbParkingMap = !this.curbParkingMap
    this.setHasEdits(true)
  }

  toggleCurbTransactionsTable() {
    this.curbTransactionsTable = !this.curbTransactionsTable
    this.setHasEdits(true)
  }

  toggleCurbDistrictAnalysis() {
    this.curbDistrictAnalysis = !this.curbDistrictAnalysis
    this.setHasEdits(true)
  }

  toggleCurbRateTester() {
    this.curbRateTester = !this.curbRateTester
    this.setHasEdits(true)
  }

  toggleCurbOccupancy() {
    this.curbOccupancy = !this.curbOccupancy
    this.setHasEdits(true)
  }

  toggleCurbFeesAndRevenue() {
    this.curbFeesAndRevenue = !this.curbFeesAndRevenue
    this.setHasEdits(true)
  }

  toggleCurbActivity() {
    this.curbActivity = !this.curbActivity
    this.setHasEdits(true)
  }

  togglePoliciesLibrary() {
    this.policiesLibrary = !this.policiesLibrary
    this.setHasEdits(true)
  }

  toggleEnableTrialPeriod() {
    this.enableTrialPeriod = !this.enableTrialPeriod
    this.setHasEdits(true)
  }

  setTrialDateRangeDefault() {
    if (!this.trialDateRange.start) {
      this.trialDateRange.start = appUIStore.metro.today.endOf('day')
    }
    if (!this.trialDateRange.end) {
      this.trialDateRange.end = appUIStore.metro.today.endOf('day').plus({ month: 1 })
    }
  }

  setTrialDateRange(trialDateRange) {
    adjustDateRange(this.trialDateRange, trialDateRange)
    this.setHasEdits(true)
  }

  subscriptionPlansMenuOptions = [
    { value: 'basic', text: 'Basic' },
    { value: 'essentials', text: 'Essentials' },
    { value: 'pro', text: 'Pro' },
    { value: 'custom', text: 'Custom' },
  ]

  feeStructureOptions = [
    { value: 'paid', text: 'Paid Contract' },
    { value: 'unpaid', text: 'Unpaid / No Contract' },
    { value: 'pilot', text: 'Pilot' },
    { value: null, text: 'Not Specified' },
  ]

  get regionMetros() {
    if (this.organizationType !== 'City') return []
    return this.selectedRegions.map(region => metroStore.getByRegionId(region))
  }

  get allMetroOperators() {
    // Takes the operator arrays for each region, combines and dedupes them, and returns as an array.
    return [...new Set(this.regionMetros.map(metro => metro.mdsOperators).flat())]
  }

  get availableRegionsMenuOptions() {
    const metroObjs = values(metroStore.metroMap)
    return _.sortBy(
      metroObjs.map(m => {
        const value = m.regionId
        const text = m.text
        return { value, text }
      }),
      r => r.text.toLowerCase()
    )
  }

  availableOperatorsMenuOptions(options, disabled = false) {
    const operators = _.map(options, operator => {
      const option = {
        value: operator,
        text: getOperatorDisplayName(operator),
      }
      if (disabled) option['disabled'] = true
      return option
    })
    return _.sortBy(operators, o => o.text.toLowerCase())
  }

  get availableDashboardsMenuOptions() {
    const availableDashboardsMenuOptions = [
      { value: 'All', text: 'All Dashboards' },
      { value: 'Default', text: 'Default Dashboards' },
    ]
      .concat(dashboards)
      .map(dashboard => {
        const { value, text } = dashboard
        return { value, text }
      })
      .filter(d => !['timeDistance', 'micromobilityParking'].includes(d.value))
    return availableDashboardsMenuOptions
  }

  get availableMobilityDashboardsMenuOptions() {
    const availableMobilityDashboardsMenuOptions = dashboards
      .slice()
      .map(dashboard => {
        const { value, text } = dashboard
        return { value, text }
      })
      .filter(d => ['timeDistance', 'micromobilityParking'].includes(d.value))

    availableMobilityDashboardsMenuOptions.unshift({ value: 'All', text: 'All Dashboards' })
    return availableMobilityDashboardsMenuOptions
  }

  // this just modifies the UI
  newOrganization() {
    this.clear()
    this.organizationName = 'New Organization'
    this.organizationType = null
    this.setSubscriptionPlan('basic')
    this.setHasEdits(true)
    this.mode = 'create'
    this.setActiveTab(0)
  }

  cancelNewOrganization() {
    permissionsModalStore.loadOrganization(permissionsModalStore.populusUuid)
    this.setActiveTab(0)
  }

  // convert this store to organization attributes to serialize to server
  get organizationJson() {
    return {
      organization_name: this.organizationName,
      organization_type: this.organizationType,
      micromobility_plan_name: this.subscriptionPlan === 'custom' ? null : this.subscriptionPlan,
      mobility_fee_structure: this.mobilityFeeStructure || null,
      curb_fee_structure: this.curbFeeStructure || null,
      mobility_populus_enforced_invoicing: this.mobilityPopulusEnforcedInvoicing || false,
      curb_populus_enforced_invoicing: this.curbPopulusEnforcedInvoicing || false,
      regions: this.allRegions ? null : this.selectedRegionsJS,
      operators: this.allOperators ? null : this.selectedOperatorsJS,
      subscription_start_date: this.enableTrialPeriod
        ? this.trialDateRange.start.toISODate()
        : null,
      subscription_end_date: this.enableTrialPeriod ? this.trialDateRange.end.toISODate() : null,
      mobility_manager: this.mobilityManager,
      curb_manager: this.curbManager,
      maps: this.allMaps ? null : getKeysWhereTrue(this.maps),
      vehicle_and_trip_counts: this.vehicleAndTripCounts,
      mobility_dashboards: this.allMobilityDashboards
        ? null
        : getKeysWhereTrue(this.mobilityDashboards),
      export: this.export,
      home: this.home,
      mobility_metrics: this.mobilityMetrics,
      fee_summary: this.feeSummary,
      compliance_reporting: this.complianceReporting,
      compliance_details: this.complianceDetails,
      mobility_maps_download: this.mobilityMapsDownload || false,
      disaggregated_data: this.disaggregatedData,
      curb_metrics_page: this.curbMetricsPage,
      curb_parking_insights: this.curbParkingInsights,
      curb_parking_map: this.curbParkingMap,
      curb_transactions_table: this.curbTransactionsTable,
      curb_district_analysis: this.curbDistrictAnalysis,
      curb_rate_tester: this.curbRateTester,
      curb_occupancy: this.curbOccupancy,
      curb_regulations_library: this.curbRegulationsLibrary,
      bikeshare_station_report: this.bikeshareStationReport,
      curb_regulations: this.curbRegulations,
      curb_tickets_map: this.curbTicketsMap,
      curb_fees_and_revenue: this.curbFeesAndRevenue,
      curb_performance: this.curbActivity,
      policies_library: this.policiesLibrary,
      policy_compliance_notifications: policyComplianceNotificationsStore.hasNotifications,
      mfa_required: this.mfaRequired,
      live_map_mobile: this.liveMapMobile,
    }
  }

  // this creates in the DB
  async saveOrganization() {
    const { mode } = this
    const endpoint = mode === 'create' ? 'create' : 'update'
    const success = true

    this.clearOrganizationMessage()
    try {
      if (success) {
        const { data } = await apipyRequest(
          `/users/${endpoint}_organization`,
          {
            organization_data: this.organizationJson,
            organization_uuid: mode === 'update' && this.organizationUuid,
          },
          { reject: true }
        )

        removeQueryCache({ queryKey: ['/users/me/permissions'] })

        runInAction(() => {
          this.organizationMessage = {
            error: false,
            heading: 'Success',
            text: `Organization ${mode === 'create' ? 'Created' : 'Updated'} successfully`,
          }
        })

        // technically don't have to do this, but good for testing
        if (mode === 'create') {
          this.loadOrganizationNames(true)
          this.loadOrganization(data.organization_uuid)
        } else {
          this.loadOrganization(this.organizationUuid)
        }
      }
    } catch (error) {
      runInAction(() => {
        this.organizationMessage = {
          error: true,
          heading: 'Error',
          text: error,
        }
      })
    }
  }

  setOrganizationLogo(file) {
    return new Promise((resolve, reject) => {
      this.clearOrganizationMessage()
      apipyRequest(
        `/users/set_organization_logo`,
        {
          organization_uuid: this.organizationUuid,
          logo: file,
          logoMimeType: file.type,
        },
        { reject: true, useMultipartFormData: true }
      )
        .then(() => {
          runInAction(() => {
            this.organizationMessage = {
              error: false,
              heading: 'Success',
              text: `Organization logo updated successfully`,
            }
          })

          this.loadOrganization(this.organizationUuid)

          resolve()
        })
        .catch(error => {
          runInAction(() => {
            this.organizationMessage = {
              error: true,
              heading: 'Error',
              text: error,
            }
          })

          reject()
        })
    })
  }

  removeOrganizationLogo() {
    this.clearOrganizationMessage()
    apipyRequest(
      `/users/remove_organization_logo`,
      {
        organization_uuid: this.organizationUuid,
      },
      { reject: true }
    )
      .then(() => {
        runInAction(() => {
          this.organizationMessage = {
            error: false,
            heading: 'Success',
            text: `Organization logo removed successfully`,
          }
        })

        this.loadOrganization(this.organizationUuid)
      })
      .catch(error => {
        runInAction(() => {
          this.organizationMessage = {
            error: true,
            heading: 'Error',
            text: error,
          }
        })
      })
  }

  deleteOrganization(completedCallback) {
    this.clearOrganizationMessage()
    apipyRequest(
      `/users/delete_organization`,
      {
        organization_uuid: this.organizationUuid,
      },
      { reject: true }
    )
      .then(() => {
        this.loadOrganizationNames()
        this.setActiveTab(0)
        completedCallback()
      })
      .catch(error => {
        runInAction(() => {
          this.organizationMessage = {
            error: true,
            heading: 'Error',
            text: error,
          }
        })
      })
  }

  clearOrganizationMessage() {
    this.organizationMessage = undefined
  }

  clearUserMessage() {
    this.userMessage = undefined
  }

  addUser() {
    this.clearUserMessage()
    apipyRequest(
      `/users/create_user`,
      {
        organization_uuid: this.organizationUuid,
        email: this.newUserEmail,
        password: this.newUserPassword,
      },
      { reject: true }
    )
      .then(() => this.addUserToIntercom())
      .catch(error => {
        runInAction(() => {
          this.userMessage = {
            error: true,
            heading: 'Error',
            text: error,
          }
        })
      })
  }

  addUserToIntercom() {
    let text = 'User created successfully'
    apipyRequest(
      `/intercom/create_user`,
      {
        email: this.newUserEmail,
        company_name: this.organizationName,
        company_id: this.organizationUuid,
      },
      { reject: true }
    )
      .then(response => {
        text += ' and added to Intercom.'
        if (response.new_company) {
          text += `\nNew Intercom company created: ${this.organizationName}`
        }
      })
      .catch(error => {
        text += `, but not added to Intercom because of the following error(s): ${error}.`
      })
      .finally(() => {
        runInAction(() => {
          this.newUserEmail = ''
          this.newUserPassword = ''
          this.userMessage = {
            error: false,
            heading: 'Success',
            text: text,
          }
        })
        // reload organization data
        this.loadOrganization(this.organizationUuid)
      })
  }

  async editUser(userData: any, editedPermissions: Permission[], editedRoles: EditUserRoleItem[]) {
    this.clearUserMessage()

    try {
      await Promise.all([
        apipyRequest(`/users/update_user`, userData, { reject: true }),
        internalApi.permissions.updatePermissionsForObj({
          objType: PermissionsObjType.USER,
          objId: userData.user_uuid,
          requestBody: editedPermissions,
        }),
        internalApi.users.updateRolesForUser({
          userId: userData.user_uuid,
          requestBody: editedRoles,
        }),
      ])
    } catch (error) {
      runInAction(() => {
        this.userMessage = {
          error: true,
          heading: 'Error',
          text: error.message,
        }
      })
    }

    this.loadOrganization(this.organizationUuid)

    // remove permission cache for user we just edited
    removeQueryCache({
      queryKey: buildGetPermissionsForObjQueryOptions({
        objType: PermissionsObjType.USER,
        objId: userData.user_uuid,
      }).queryKey,
      exact: true,
    })

    removeQueryCache({
      queryKey: buildGetRolesForUserQueryOptions({ userId: userData.user_uuid }).queryKey,
      exact: true,
    })
  }

  deleteUser(user_uuid) {
    this.clearUserMessage()
    apipyRequest(
      `/users/delete_user`,
      {
        user_uuid,
      },
      { reject: true }
    )
      .then(() => {
        runInAction(() => {
          this.users.delete(user_uuid)
          this.userMessage = {
            error: false,
            heading: 'Success',
            text: 'User deleted successfully',
          }
        })
        // reload organization data
        this.loadOrganization(this.organizationUuid)
      })
      .catch(error => {
        runInAction(() => {
          this.userMessage = {
            error: true,
            heading: 'Error',
            text: error,
          }
        })
      })
  }

  loadOrganizationNames(dontLoadDefaultOrg = false) {
    apipyRequest(`/users/organization_names`).then(response => {
      runInAction(() => (this.organizationNames = response.data))
      if (!dontLoadDefaultOrg) {
        const populusUuid = _.find(response.data, {
          organization_name: 'Populus',
        }).organization_uuid
        this.populusUuid = populusUuid
        this.loadOrganization(populusUuid)
      }
    })
  }

  clear() {
    this.setHasEdits(false)
    this.mode = 'update'
    this.organizationName = ''
    this.organizationType = null
    this.organizationUuid = undefined
    this.mobilityFeeStructure = null
    this.curbFeeStructure = null
    this.mobilityPopulusEnforcedInvoicing = false
    this.curbPopulusEnforcedInvoicing = false
    this.allRegions = false
    this.selectedRegions = []
    this.allOperators = false
    this.selectedOperators = []
    this.subscriptionPlan = 'basic'
    this.vehicleAndTripCounts = 'basic'
    this.export = false
    this.home = false
    this.mobilityMetrics = false
    this.feeSummary = false
    this.complianceReporting = false
    this.complianceDetails = false
    this.mobilityMapsDownload = false
    this.disaggregatedData = false
    this.coverage = false
    this.mobilityManager = false
    this.curbManager = false
    this.curbMetricsPage = false
    this.curbParkingInsights = false
    this.curbParkingMap = false
    this.curbTransactionsTable = false
    this.curbDistrictAnalysis = false
    this.curbRateTester = false
    this.curbOccupancy = false
    this.curbRegulations = false
    this.curbRegulationsLibrary = false
    this.bikeshareStationReport = false
    this.curbFeesAndRevenue = false
    this.curbActivity = false
    this.policiesLibrary = false
    this.enableTrialPeriod = false
    this.trialDateRange = {
      start: null,
      end: null,
    }
    this.allMaps = false
    this.maps.clear()
    this.allMobilityDashboards = false
    this.mobilityDashboards.clear()
    this.users.clear()
    policyComplianceNotificationsStore.setHasNotifications(false)
    policyComplianceNotificationsStore.setAllNotifications()

    this.newUserPassword = ''
    this.newUserEmail = ''

    this.clearOrganizationMessage()
    this.clearUserMessage()
  }

  setMobilityManagerPermissions(obj) {
    if (!obj) return
    this.export = obj.export
    this.home = obj.home
    this.mobilityMetrics = obj.mobility_metrics
    this.feeSummary = obj.fee_summary
    this.complianceReporting = obj.compliance_reporting
    this.complianceDetails = obj.compliance_details
    this.mobilityMapsDownload = obj.mobility_maps_download
    this.vehicleAndTripCounts = obj.vehicle_and_trip_counts

    const { maps, mobility_dashboards } = obj

    this.allMaps = maps === null
    this.maps.clear()
    if (maps !== null) {
      maps.forEach(map => this.maps.set(map, true))
    }

    this.allMobilityDashboards = mobility_dashboards === null
    this.mobilityDashboards.clear()
    if (mobility_dashboards !== null) {
      mobility_dashboards.forEach(dashboard => this.mobilityDashboards.set(dashboard, true))
    }
  }

  setOrganizationPermissions(organization) {
    this.setHasEdits(false)

    this.organizationName = organization.organization_name
    this.organizationType = organization.organization_type
    this.organizationLogo = organization.organization_logo_url
    this.organizationUuid = organization.organization_uuid
    this.subscriptionPlan = organization.micromobility_plan_name || 'custom'
    this.mobilityFeeStructure = organization.mobility_fee_structure
    this.mobilityPopulusEnforcedInvoicing = organization.mobility_populus_enforced_invoicing
    this.mobilityMapsDownload = organization.mobility_maps_download
    this.curbFeeStructure = organization.curb_fee_structure
    this.curbPopulusEnforcedInvoicing = organization.curb_populus_enforced_invoicing
    this.disaggregatedData = organization.disaggregated_data

    this.setMobilityManagerPermissions(organization)

    this.operatorId = organization.operator_id

    this.allRegions = organization.regions === null
    this.selectedRegions = organization.regions
    this.allOperators = organization.operators === null
    this.selectedOperators = organization.operators

    const { subscription_start_date, subscription_end_date } = organization

    this.mobilityManager = organization.mobility_manager
    this.curbManager = organization.curb_manager

    this.curbMetricsPage = organization.curb_metrics_page
    this.curbParkingInsights = organization.curb_parking_insights
    this.curbParkingMap = organization.curb_parking_map
    this.curbTransactionsTable = organization.curb_transactions_table
    this.curbDistrictAnalysis = organization.curb_district_analysis
    this.curbRateTester = organization.curb_rate_tester
    this.curbOccupancy = organization.curb_occupancy
    this.curbTicketsMap = organization.curb_tickets_map
    this.curbRegulationsLibrary = organization.curb_regulations_library
    this.bikeshareStationReport = organization.bikeshare_station_report
    this.curbRegulations = organization.curb_regulations
    this.curbFeesAndRevenue = organization.curb_fees_and_revenue
    this.curbActivity = organization.curb_performance
    this.mfaRequired = organization.mfa_required
    this.liveMapMobile = organization.live_map_mobile
    this.policiesLibrary = organization.policies_library
    policyComplianceNotificationsStore.setHasNotifications(
      organization.policy_compliance_notifications
    )

    this.enableTrialPeriod = subscription_start_date !== null || subscription_end_date !== null
    if (this.enableTrialPeriod) {
      this.trialDateRange = {
        start: DateTime.fromISO(subscription_start_date),
        end: DateTime.fromISO(subscription_end_date),
      }
    } else {
      this.setTrialDateRangeDefault()
    }
  }

  setUsers(users) {
    _.each(users, user => {
      this.users.set(user.user_uuid, user)
    })
  }

  loadOrganization(organization_uuid) {
    if (organization_uuid !== this.organizationUuid) {
      this.clear()
    }
    apipyRequest(`/users/get_organization`, { organization_uuid }).then(response => {
      const { organization, users } = response.data
      this.setOrganizationPermissions(organization)
      this.setUsers(users)
      if (this.organizationType === 'City')
        policyComplianceNotificationsStore.loadAllNotifications()
    })
  }

  get activePlan() {
    return toJS(this.micromobilityPlans.get(this.subscriptionPlan)) || {}
  }

  get availableUserPermissions() {
    // TODO: this currently encompasses any permission defined, which may include org/user_role permissions, eventually we'll want to only include permissions able to be set for an individual user
    return enumStringValues(PermissionKey)
      .map(permissionKey => ({
        label: formatText(permissionKey),
        key: permissionKey,
      }))
      .filter(p => p.key !== PermissionKey.IS_ADMIN)
  }

  loadPlans() {
    apipyRequest(`/config/get_micromobility_plan_tiers`).then(response => {
      runInAction(() =>
        response.data.forEach(plan =>
          this.micromobilityPlans.set(plan.micromobility_plan_name, plan)
        )
      )
    })
  }
}

const permissionsModalStore = new PermissionsModalStore()

export default permissionsModalStore
