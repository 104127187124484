import { useTranslation } from 'react-i18next'

import {
  useCurbActivityAccess,
  useCurbFeesAndRevenueAccess,
  useCurbGeographiesMapAccess,
  useCurbMetricsAccess,
  useCurbParkingInsightsAccess,
  useCurbParkingMapAccess,
  useCurbParkingTransactionsAccess,
  useCurbRegulationsAccess,
  useMobilityActivitySummaryAccess,
  useMobilityDistributionMapAccess,
  useMobilityGeographiesMapAccess,
  useMobilityInvoicingAccess,
  useMobilityIssuesMapAccess,
  useMobilityLiveMapAccess,
  useMobilityMDSHealthAccess,
  useMobilityOperatorDropOffsMapAccess,
  useMobilityParkingMapAccess,
  useMobilityPoliciesAccess,
  useMobilityPolicyComplianceAccess,
  useMobilityReportingMobilityMetricsAccess,
  useMobilityReportingParkingAccess,
  useMobilityReportingTimeDistanceAccess,
  useMobilityReportingTripCountsAccess,
  useMobilityRoutesMapAccess,
  useMobilityTripsMapAccess,
} from '@/modules/permissions/pageAccessHooks'
import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PATHS, SEGMENTS } from '@/modules/urlRouting/paths'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'

import { ShelfItemDrawerExpand } from './ShelfItemDrawerExpand'
import { ShelfItemNavLink } from './ShelfItemNavLink'

export interface ShelfLinksProps {
  activePath?: string
  expanded?: boolean
  regionId?: string
  setActivePath?: (path: string) => void
  setExpanded?: (isOpen: boolean) => void
}

/**
 * Array of navigation links for the shelf when viewing Curb Manager
 */
const CurbShelfLinks = ({ regionId, ...props }: ShelfLinksProps) => {
  const curbMetricsAccess = useCurbMetricsAccess()
  const curbActivityAccess = useCurbActivityAccess()
  const curbRegulationAccess = useCurbRegulationsAccess()
  const curbFeesAndRevenueAccess = useCurbFeesAndRevenueAccess()
  const curbGeographiesAccess = useCurbGeographiesMapAccess()
  const curbParkingInsightsAccess = useCurbParkingInsightsAccess()
  const curbParkingTransactionsAccess = useCurbParkingTransactionsAccess()
  const curbParkingMapAccess = useCurbParkingMapAccess()

  const { t } = useTranslation()

  return (
    <>
      <ShelfItemDrawerExpand
        disabled={!(curbMetricsAccess || curbActivityAccess)}
        iconName="Dashboard"
        path={PATHS.CURB.MANAGEMENT._ANY}
        text={t('urlRouting.shelfLinkManagement', 'Management')}
        {...props}
      />
      <ShelfItemDrawerExpand
        disabled={
          !(curbParkingInsightsAccess || curbParkingTransactionsAccess || curbParkingMapAccess)
        }
        iconName="Parking"
        path={PATHS.CURB.PARKING_INSIGHTS._ANY}
        text={t('urlRouting.shelfLinkParkingInsights', 'Parking Insights')}
        {...props}
      />
      <ShelfItemNavLink
        dataTestId="curbRegulations"
        disabled={!curbRegulationAccess}
        iconName="Regulations"
        text={t('urlRouting.shelfLinkRegulations', 'Regulations')}
        to={generatePathWithRegionId(PATHS.CURB.REGULATIONS.MAP, regionId)}
      />
      <ShelfItemDrawerExpand
        disabled={!curbFeesAndRevenueAccess}
        dataTestId="revenue"
        iconName="Revenue"
        path={PATHS.CURB.REVENUE._ANY}
        text={t('urlRouting.shelfLinkRevenue', 'Revenue')}
        {...props}
      />
      <ShelfItemNavLink
        dataTestId="curbGeographies"
        disabled={!curbGeographiesAccess}
        iconName="GeographiesAreasLocations"
        text={t('urlRouting.shelfLinkGeographies', 'Geographies')}
        to={generatePathWithRegionId(PATHS.CURB.GEOGRAPHIES, regionId)}
      />
    </>
  )
}

/**
 * Array of navigation links for the shelf when viewing Mobility Manager
 */
export const MobilityShelfLinks = ({ regionId, ...props }: ShelfLinksProps) => {
  const { t } = useTranslation()
  const activitySummaryAccess = useMobilityActivitySummaryAccess()
  const liveMapAccess = useMobilityLiveMapAccess()
  const issuesMapAccess = useMobilityIssuesMapAccess()
  const distributionMapAccess = useMobilityDistributionMapAccess()
  const operatorDropOffMapAccess = useMobilityOperatorDropOffsMapAccess()
  const parkingMapAccess = useMobilityParkingMapAccess()
  const routesMapAccess = useMobilityRoutesMapAccess()
  const tripsMapAccess = useMobilityTripsMapAccess()
  const mobilityReportingTripCountsAccess = useMobilityReportingTripCountsAccess()
  const mobilityReportingMobilityMetricsAccess = useMobilityReportingMobilityMetricsAccess()
  const mobilityReportingTimeDistanceAccess = useMobilityReportingTimeDistanceAccess()
  const mobilityReportingParkingAccess = useMobilityReportingParkingAccess()
  const policiesAccess = useMobilityPoliciesAccess()
  const policyComplianceAccess = useMobilityPolicyComplianceAccess()
  const mobilityGeographiesMapAccess = useMobilityGeographiesMapAccess()
  const mdsHealthAccess = useMobilityMDSHealthAccess()
  const feesInvoicing = useMobilityInvoicingAccess()

  return (
    <>
      <ShelfItemNavLink
        data-testid="mobilityHome"
        dataTestId="mobilityHome"
        disabled={!activitySummaryAccess}
        iconName="Dashboard"
        text={t('urlRouting.shelfLinkHome', 'Home')}
        to={generatePathWithRegionId(PATHS.MOBILITY.ACTIVITY_SUMMARY, regionId)}
      />
      <ShelfItemDrawerExpand
        disabled={
          !(
            liveMapAccess ||
            issuesMapAccess ||
            distributionMapAccess ||
            operatorDropOffMapAccess ||
            parkingMapAccess ||
            routesMapAccess ||
            tripsMapAccess
          )
        }
        data-testid="maps"
        dataTestId="maps"
        iconName="Maps"
        path={PATHS.MOBILITY.MAPS._ANY}
        text={t('urlRouting.shelfLinkMaps', 'Maps')}
        {...props}
      />
      <ShelfItemDrawerExpand
        data-testid="reporting"
        dataTestId="reporting"
        disabled={
          !(
            mobilityReportingTripCountsAccess ||
            mobilityReportingMobilityMetricsAccess ||
            mobilityReportingTimeDistanceAccess ||
            mobilityReportingParkingAccess
          )
        }
        iconName="Reporting"
        path={PATHS.MOBILITY.REPORTING._ANY}
        text={t('urlRouting.shelfLinkReporting', 'Reporting')}
        {...props}
      />
      <ShelfItemDrawerExpand
        data-testid="policies"
        dataTestId="policies"
        disabled={!(policiesAccess || policyComplianceAccess)}
        iconName="Regulations"
        path={`${PATHS.MOBILITY.POLICIES._ANY}`}
        text={t('urlRouting.shelfLinkPolicies', 'Policies')}
        {...props}
      />
      <ShelfItemNavLink
        data-testid="invoicing"
        dataTestId="invoicing"
        disabled={!feesInvoicing}
        iconName="FeesInvoicing"
        text={t('urlRouting.shelfLinkPoliciesFeesInvoicing', 'Policy Fees and Invoicing')}
        to={generatePathWithRegionId(PATHS.MOBILITY.INVOICING, regionId)}
      />
      <ShelfItemNavLink
        data-testid="mobilityGeographies"
        dataTestId="mobilityGeographies"
        disabled={!mobilityGeographiesMapAccess}
        iconName="GeographiesAreasLocations"
        text={t('urlRouting.shelfLinkGeographies', 'Geographies')}
        to={generatePathWithRegionId(PATHS.MOBILITY.GEOGRAPHIES, regionId)}
      />
      <ShelfItemNavLink
        data-testid="mdsDataHealth"
        dataTestId="mdsDataHealth"
        disabled={!mdsHealthAccess}
        iconName="IssuesProblems"
        text={t('urlRouting.shelfLinkMDSDataHealth', 'MDS Data Health')}
        to={generatePathWithRegionId(PATHS.MOBILITY.MDS_DATA_HEALTH, regionId)}
      />
    </>
  )
}

/**
 * Renders shelf items associated with the current product
 * @param activePath - path associated with the shelf item currently selected by a user (e.g., `:regionId/mobility/maps/*`)
 * @param expanded - whether the drawer is expanded / visible
 * @param setActivePath - callback to update active path when a new item is clicked
 * @param setExpanded - callback to expand / close the drawer
 */
const ShelfLinks = (props: Exclude<ShelfLinksProps, 'regionId'>) => {
  const product = useCurrentProduct()
  const {
    data: { regionId },
  } = useCurrentRegion()

  if (!regionId) return null
  if (product === SEGMENTS.MOBILITY) return <MobilityShelfLinks {...props} regionId={regionId} />
  if (product === SEGMENTS.CURB) return <CurbShelfLinks {...props} regionId={regionId} />

  return null
}

export default ShelfLinks
