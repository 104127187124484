import { startCase as lodashStartCase } from 'lodash'

import { PopulusColor } from '@/components/componentLibrary/sharedTypes'

// Formats the text into a readable format (removes underscores and changes to uppercase)
export function formatText(text: string | undefined) {
  if (!text) return ''

  return text
    .replaceAll('_', ' ')
    .split(' ')
    .map(s => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ')
}

/**
 * Get Text Color based upon Background Color
 * Originally from https://stackoverflow.com/a/41491220
 * @date 2023-03-16
 * @param {string} bgColor:string
 * @param {string} lightColor:string
 * @param {string} darkColor:string
 * @returns {string}
 */
export const getTextColorBasedUponBackground = (
  backgroundColor: string,
  lightTextColor: PopulusColor = 'white',
  darkTextColor: PopulusColor = 'black'
) => {
  if (backgroundColor.charAt(0) !== '#')
    throw TypeError('bgColor not in correct hex format (#ffffff)')

  const LUMINOSITY_LIMIT = 0.179
  const color = backgroundColor.substring(1, 7)
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uiColors = [r / 255, g / 255, b / 255]
  const c = uiColors.map(color => {
    if (color <= 0.03928) return color / 12.92
    return Math.pow((color + 0.055) / 1.055, 2.4)
  })
  const luminosity = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]

  return luminosity > LUMINOSITY_LIMIT ? darkTextColor : lightTextColor
}

export const startCase = (string: string | undefined) => {
  return lodashStartCase(string)
}

/**
 * Removes all non-alphanumeric from the input string.
 * @param value - string to be stripped
 * @returns new string with only alphanumeric values (i.e. a-z, A-Z, 0-9)
 */
export const stripNonAlphaNumeric = (value: string) => value.replace(/[^A-Za-z0-9]/g, '')

export const whiteTextHalo = {
  textShadow: `-1px -1px 0 white, 1px -1px 0 white, -1px 1px 0 white, 1px 1px 0 white,
    -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white, 2px 2px 0 white,
    -1px -2px 0 white, 1px -2px 0 white, -1px 2px 0 white, 1px 2px 0 white,
    -2px -1px 0 white, 2px -1px 0 white, -2px 1px 0 white, 2px 1px 0 white,
    -0px -2px 0 white, 0px -2px 0 white, -0px 2px 0 white, 0px 2px 0 white,
    -2px -0px 0 white, 2px -0px 0 white, -2px 0px 0 white, 2px 0px 0 white`,
}
