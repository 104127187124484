import { observer } from 'mobx-react'
import { Checkbox } from 'semantic-ui-react'

import permissionsModalStore from '@/modules/permissions/adminPanel/permissionsModalStore'

import './CurbManager.scss'

export default observer(() => {
  return (
    <>
      <div className="checkbox-list">
        <Checkbox
          toggle
          label="Curb Manager Access"
          checked={permissionsModalStore.curbManager}
          onChange={() => permissionsModalStore.toggleCurbManager()}
        />
        {permissionsModalStore.curbManager && (
          <>
            <Checkbox
              label="Curb Metrics Page"
              toggle
              checked={permissionsModalStore.curbMetricsPage}
              onChange={() => permissionsModalStore.toggleCurbMetricsPage()}
            />
            <Checkbox
              label="Curb Activity Page"
              toggle
              checked={permissionsModalStore.curbActivity}
              onChange={() => permissionsModalStore.toggleCurbActivity()}
            />
            <Checkbox
              label="Parking Insights"
              toggle
              checked={permissionsModalStore.curbParkingInsights}
              onChange={() => permissionsModalStore.toggleCurbParkingInsights()}
            />
            <Checkbox
              label="District Analysis"
              toggle
              checked={permissionsModalStore.curbDistrictAnalysis}
              onChange={() => permissionsModalStore.toggleCurbDistrictAnalysis()}
            />
            <Checkbox
              label="Rate Analyzer"
              toggle
              checked={permissionsModalStore.curbRateTester}
              onChange={() => permissionsModalStore.toggleCurbRateTester()}
            />
            <Checkbox
              label="Occupancy"
              toggle
              checked={permissionsModalStore.curbOccupancy}
              onChange={() => permissionsModalStore.toggleCurbOccupancy()}
            />
            <Checkbox
              label="Transactions"
              toggle
              checked={permissionsModalStore.curbTransactionsTable}
              onChange={() => permissionsModalStore.toggleCurbTransactionsTable()}
            />
            <Checkbox
              label="Parking Map"
              toggle
              checked={permissionsModalStore.curbParkingMap}
              onChange={() => permissionsModalStore.toggleCurbParkingMap()}
            />
            <Checkbox
              label="Regulations Library"
              toggle
              checked={permissionsModalStore.curbRegulationsLibrary}
              onChange={() => permissionsModalStore.toggleCurbRegulationsLibrary()}
            />
            <Checkbox
              label="Regulations Map"
              toggle
              checked={permissionsModalStore.curbRegulations}
              onChange={() => permissionsModalStore.toggleCurbRegulations()}
            />
            <Checkbox
              label="Fees and Revenue Page"
              toggle
              checked={permissionsModalStore.curbFeesAndRevenue}
              onChange={() => permissionsModalStore.toggleCurbFeesAndRevenue()}
            />
          </>
        )}
      </div>
    </>
  )
})
