/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CensusResultModel } from '../models/CensusResultModel'
import type { CreateLayerBody } from '../models/CreateLayerBody'
import type { LayerResponse } from '../models/LayerResponse'
import type { ListLayersPayload } from '../models/ListLayersPayload'
import type { ListLayersResponseModel } from '../models/ListLayersResponseModel'
import type { PopulusPage_LayerResponse_ } from '../models/PopulusPage_LayerResponse_'
import type { UpdateLayerBody } from '../models/UpdateLayerBody'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class LayersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @deprecated
   * List Layers Deprecated
   * Returns shape_layers for a specific region, which must be specified
   * on the request as region_id.
   * @returns ListLayersResponseModel Successful Response
   * @throws ApiError
   */
  public listLayersDeprecated({
    requestBody,
  }: {
    requestBody: ListLayersPayload
  }): CancelablePromise<ListLayersResponseModel> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/layers/list',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Census Data For Shape Layer
   * @returns CensusResultModel Successful Response
   * @throws ApiError
   */
  public getCensusDataForShapeLayer({
    shapeLayerUuid,
  }: {
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
  }): CancelablePromise<Record<string, CensusResultModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/layers/{shape_layer_uuid}/census',
      path: {
        shape_layer_uuid: shapeLayerUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Layers
   * Returns shape_layers for a specific region
   * @returns PopulusPage_LayerResponse_ Successful Response
   * @throws ApiError
   */
  public getLayers({
    regionId,
    page = 1,
    size = 50,
    shapeLayerUuid,
    layerName,
    layerSlug,
    level,
    intersectRoute,
    vehicleCounts,
    vehicleCountsStatus,
    editable,
    search,
    orderBy = 'level,layer_name',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    page?: number
    size?: number
    shapeLayerUuid?: string
    layerName?: string
    layerSlug?: string
    level?: string
    intersectRoute?: boolean
    vehicleCounts?: boolean
    vehicleCountsStatus?: string
    editable?: boolean
    search?: string
    orderBy?: string
  }): CancelablePromise<PopulusPage_LayerResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
        shape_layer_uuid: shapeLayerUuid,
        layer_name: layerName,
        layer_slug: layerSlug,
        level: level,
        intersect_route: intersectRoute,
        vehicle_counts: vehicleCounts,
        vehicle_counts_status: vehicleCountsStatus,
        editable: editable,
        search: search,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Create Layer
   * Add a layer to the shape_layers table.
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public createLayer({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: CreateLayerBody
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/layers',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Layer
   * Retrieve a layer by slug, only showing it if it is available for the requesting user
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public getLayer({
    regionId,
    shapeLayerUuid,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    accept?: string
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      headers: {
        accept: accept,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Delete Layer
   * Delete a shape from the shapes table.
   * @returns void
   * @throws ApiError
   */
  public deleteLayer({
    regionId,
    shapeLayerUuid,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Layer
   * Modify an existing shape in the shapes table. Can be used to modify the shape name.
   * @returns LayerResponse Successful Response
   * @throws ApiError
   */
  public updateLayer({
    regionId,
    shapeLayerUuid,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    requestBody: UpdateLayerBody
  }): CancelablePromise<LayerResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Layer Vector
   * Retrieve a layer by slug, only showing it if it is available for the requesting user as an MVT
   * @returns string Successful Response
   * @throws ApiError
   */
  public getLayerVector({
    regionId,
    shapeLayerUuid,
    z,
    x,
    y,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    z: number
    x: number
    y: number
    ifModifiedSince?: string
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
        z: z,
        x: x,
        y: y,
      },
      query: {
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Does Layer Have Policies
   * Returns whether a shape layer has any non-deleted policies (v1 or v2) attached to it
   * @returns boolean Successful Response
   * @throws ApiError
   */
  public getDoesLayerHavePolicies({
    regionId,
    shapeLayerUuid,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
  }): CancelablePromise<boolean> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/has_policies',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
