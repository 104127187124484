import { FC, useEffect, useState } from 'react'
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth'
import { useTranslation } from 'react-i18next'
import { multiFactor } from 'firebase/auth'
import { Checkbox, Message, Modal } from 'semantic-ui-react'

import { LocalStorageKey, setLocalStorageValue } from '@/common/utils/localStorage'
import { Button, Icon, Text } from '@/components/componentLibrary'
import { fbAuth, signOut } from '@/modules/authentication'
import { useCurrentUser, usePatchCurrentUser } from '@/modules/user/hooks'

import './profileModal.scss'

const ProfileModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const [sendPasswordResetEmail, resetSending, resetError] = useSendPasswordResetEmail(fbAuth)
  const [passwordResetSent, setPasswordResetSent] = useState(false)
  const { data: user } = useCurrentUser()
  const { t } = useTranslation()
  const [enrolledMfa, setEnrolledMfa] = useState(false)

  useEffect(() => {
    if (fbAuth.currentUser) {
      const multiFactorUser = multiFactor(fbAuth.currentUser)
      if (multiFactorUser.enrolledFactors.length > 0) setEnrolledMfa(true)
    }
  }, [fbAuth])

  const resetPassword = async () => {
    await sendPasswordResetEmail(user!.email)
    setPasswordResetSent(true)
  }

  const removeMfa = () => {
    setLocalStorageValue(LocalStorageKey.UNENROLL_MFA, 'true')
    signOut()
  }

  const addMfa = () => {
    setLocalStorageValue(LocalStorageKey.ENROLL_MFA, 'true')
    signOut()
  }

  const CloseFiltersCheckbox: FC = () => {
    const [checked, setChecked] = useState(!!user?.defaultFilterSectionClosed)
    const { mutate: patchCurrentUser } = usePatchCurrentUser()

    const handleChange = () => {
      const newChecked = !checked
      setChecked(newChecked)

      if (user) patchCurrentUser({ defaultFilterSectionClosed: newChecked })
    }

    return (
      <Checkbox
        toggle
        label={t('profileModal.closeFiltersDefault', 'Close map filters by default')}
        checked={checked}
        onChange={handleChange}
      />
    )
  }

  return (
    <Modal className="edit-profile-modal" open={true} onClose={onClose} size="small">
      <Modal.Content className="content">
        <div className="settings-header">
          <Text styleType="link-special">
            {t('profileModal.profileSettings', 'Profile Settings')}
          </Text>
          <Icon icon="CloseRoundedSmall" onClick={onClose} hoverColor="ocean-1" small />
        </div>
        <div className="settings-section">
          <Text styleType="header-4-light">{t('profileModal.account', 'Account')}</Text>
          <Button
            onClick={resetPassword}
            text={t('profileModal.resetPassword', 'Reset Password')}
            className="profile-reset-button"
            extraWide={true}
          />

          {enrolledMfa ? (
            <Button
              onClick={removeMfa}
              text={t('profileModal.removeMfa', 'Remove multi-factor device!')}
              className="profile-reset-button"
              extraWide={true}
            />
          ) : (
            <Button
              onClick={addMfa}
              text={t('profileModal.addMfa', 'Add multi-factor device!')}
              className="profile-reset-button"
              extraWide={true}
            />
          )}

          {passwordResetSent && !resetSending && !resetError && (
            <Message positive>
              <Message.Header>
                {t('profileModal.resetEmailHeader', 'Password reset email sent')}
              </Message.Header>
              <p>
                {t(
                  'profileModal.resetEmailMessage',
                  'An email with a link to reset your password has been sent to {{user_email}}.',
                  { user_email: user?.email || 'your email' }
                )}
              </p>
            </Message>
          )}
        </div>
        <div className="settings-section">
          <Text styleType="header-4-light">{t('profileModal.preferences', 'Preferences')}</Text>
          <CloseFiltersCheckbox />
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ProfileModal
